import axios from "axios";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { createWith } from "typescript";

const token = localStorage.getItem(`token`);

interface WithdrawalData {
  uid: string;
  amount: number;
  date: string;
  cut_fees_and_tax: number;
  withdraw_status: string;
  withdraw_id: string;
  verify_data: {
    uid: string;
    fullname: string;
    bookbank_id: string;
    bank: string;
    bankbook_image: string[];
    idcard_image: string[];
    date: string;
    status: string;
  };
  userData: {
    email_address: string | null;
    phone_number: string;
    username: string;
  };
}

interface StatData {
  pending: string;
  processing: string;
  approved: string;
  rejected: string;
}
interface dataForValidate {
  address: string | null;
  bank: string;
  bankbook_image: string[];
  bookbank_id: string;
  date: string;
  email: string | null;
  fullname: string;
  idcard_image: string[];
  status: string;
  uid: string;
}

const WithdrawLogs = () => {
  const [withdrawalData, setWithdrawalData] = useState<WithdrawalData[]>([]);
  const [statData, setStatData] = useState<StatData>({
    pending: "0",
    processing: "0",
    approved: "",
    rejected: "",
  });

  console.log("WithDrawDataLog", withdrawalData);
  const [showModalRejected, setShowModalRejected] = useState(false);
  const [targetUserUIDForRejected, setTargetUserUIDForRejected] = useState("");
  const [reasonRejected, setReasonRejected] = useState("");
  const [withdraw_idRejected, setWithdrawIDRejected] = useState("");
  const [previewImageCentral, setPreviewImageCentral] = useState(false);
  const [imageURLPreview, setImageURLPreview] = useState("");
  const [dataForValidate, setDataForValidate] = useState<dataForValidate>();
  const [modalDataValidate, setModalDataValidate] = useState(false);
  useEffect(() => {
    getWithDrawLogs();
  }, []);

  const statDisplay = [
    {
      title: "กำลังรอ (pending)",
      count: statData?.pending,
    },
    {
      title: "กำลังดำเนินการ (processing)",
      count: statData?.processing,
    },
    {
      title: "อนุมัติ (approved)",
      count: statData?.approved,
    },
    {
      title: "ปฏิเสธ (rejected)",
      count: statData?.rejected,
    },
  ];
  function getWithDrawLogs(): void {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/banking/withdrawlogs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res: any) => {
        const withDrawLogs = res.data.withdraw_data;
        const statData = res.data.stat;
        setWithdrawalData(withDrawLogs);
        setStatData(statData);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  function handleChangeStatus(
    index: number,
    newStatus: string,
    withdraw_id: string,
    uid: string
  ): void {
    if (newStatus === "approved") {
      localStorage.setItem("transactionWithDraw", withdraw_id);
      localStorage.setItem("transactionUserUID", uid);
      console.log("approved clicked");
      window.location.replace("/withdrawlogs/approved");
    } else if (newStatus === "rejected") {
      setShowModalRejected(true);
      setTargetUserUIDForRejected(uid);
      setWithdrawIDRejected(withdraw_id);
    } else {
      // If status is not approved, update the withdrawal status and send the request to the API
      const updatedWithdrawalData = [...withdrawalData];
      updatedWithdrawalData[index].withdraw_status = newStatus;
      setWithdrawalData(updatedWithdrawalData);

      axios
        .post(
          `${process.env.REACT_APP_ADMIN_API}/banking/withdraw-action`,
          {
            withdraw_id: updatedWithdrawalData[index].withdraw_id,
            status: newStatus,
            target_uid: updatedWithdrawalData[index].uid,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => {
          // Handle success response if needed
          console.log(res.data);
          Swal.fire(``, `${res.data?.message}`, "success");
        })
        .catch((err: any) => {
          console.log(err);
          // Handle error if needed
          Swal.fire(`ไม่สำเร็จ`, `${err.response.data.Error}`, "error");
        });
    }
  }

  function handleReasonRejected(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    const value = e.target.value;
    setReasonRejected(value);
  }

  function handleRejectedTransaction(): void {
    axios
      .post(
        `${process.env.REACT_APP_ADMIN_API}/banking/withdraw-action`,
        {
          withdraw_id: withdraw_idRejected,
          status: "rejected",
          target_uid: targetUserUIDForRejected,
          reason: reasonRejected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (res: any) => {
        // Handle success response if needed
        console.log(res.data);
        await Swal.fire(``, `${res.data?.message}`, "success");
        setShowModalRejected(false);
      })
      .catch((err: any) => {
        console.log(err);
        // Handle error if needed
        Swal.fire(`ไม่สำเร็จ`, `${err.response.data.Error}`, "error");
      });
  }

  function handleShowPreviewImage(imageURL: string) {
    setPreviewImageCentral(true);
    setImageURLPreview(imageURL);
  }

  function handleValidateData(verifyData: any): void {
    console.log("This is verify Data", verifyData);
    setDataForValidate(verifyData);
    setModalDataValidate(true);
  }

  function createTransactionDocument(
    verify_data: any,
    amount: any,
    isoDateString: any,
    cut_fees_and_tax: any,
    withdraw_status: string,
    withdraw_id: string,
    uid: string,
    userData: any
  ) {
    // Parse the date string
    const dateObj = new Date(isoDateString);

    // Extract day, month, and year
    const day = String(dateObj.getDate()).padStart(2, "0"); // Pads single digit days with a leading zero
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = dateObj.getFullYear();
    const yearThai = (year + 543).toString(); // Convert to Thai year

    const DataUser_transactionDocument = {
      dataOfUser: verify_data,
      amount: amount,
      date: `${day}-${month}-${yearThai}`,
      cut_fees_and_tax: cut_fees_and_tax,
      withdraw_status: withdraw_status,
      withdraw_id: withdraw_id,
      uid: uid,
      userData,
    };

    
    


    axios
      .post(
        `${process.env.REACT_APP_API_ARX}/generate_pdf`,
        {
          username: DataUser_transactionDocument?.userData?.username,
          phone_number: DataUser_transactionDocument?.userData?.phone_number,
          email: DataUser_transactionDocument?.userData?.email || "-",
          bank_provider: DataUser_transactionDocument?.dataOfUser?.bank,
          bank_id: DataUser_transactionDocument?.dataOfUser?.bookbank_id,
          fullname: DataUser_transactionDocument?.dataOfUser?.fullname,
          req_money: String(DataUser_transactionDocument?.amount),
          vat_money: String(DataUser_transactionDocument?.cut_fees_and_tax),
          result_withdraw: String(DataUser_transactionDocument?.amount),
          date: day,
          month: month,
          year_thai: yearThai,
        },
        { responseType: "blob" }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${DataUser_transactionDocument?.date}_${DataUser_transactionDocument?.userData?.username}_${DataUser_transactionDocument?.amount}THB.pdf`
        ); // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <main className="relative w-full h-full ">
      {showModalRejected ? (
        <div className="flex flex-col absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[600px] w-8/12 z-50 bg-black p-4">
          <div className="relative flex justify-center items-center w-full h-full  mx-auto">
            <div
              className="z-50 text-red-500 text-5xl absolute left-6  p-2.5 rounded-full top-2.5 cursor-pointer"
              onClick={() => setShowModalRejected(false)}
            >
              X
            </div>

            <input
              type="text"
              placeholder="ระบุเหตุผล"
              className="w-8/12 h-12 p-2.5  mx-auto rounded-full text-center"
              onChange={(e) => handleReasonRejected(e)}
            />
            <div
              onClick={() => {
                handleRejectedTransaction();
              }}
              className="absolute right-6 bottom-6 bg-red-500 text-white flex justify-center items-center p-2.5 rounded-full cursor-pointer"
            >
              ยืนยันการไม่อนุมัติ
            </div>
          </div>
        </div>
      ) : null}

      {modalDataValidate ? (
        <div className="absolute overflow-auto left-1/2 transform -translate-x-1/2  top-40 w-[1100px] h-[600px] z-50 bg-blue-500">
          <div className="relative w-full h-auto  grid grid-cols-2">
            <span
              onClick={() => setModalDataValidate(false)}
              className="absolute left-2.5 top-2.5 bg-white  text-xl px-2.5 py-1 cursor-pointer rounded-full"
            >
              X
            </span>
            <img
              src={dataForValidate?.idcard_image[0]}
              className="w-full h-full"
            />
            <div className="w-full h-full flex flex-col justify-center items-center text-white ">
              <p className="text-3xl">{ dataForValidate?.fullname}</p>
              <p>{dataForValidate?.address}</p>
              <p>{dataForValidate?.date}</p>
            </div>
            <img
              src={dataForValidate?.bankbook_image[0]}
              className="w-full h-full"
            />
            <div className="w-full h-full flex flex-col justify-center items-center text-white ">
              <p className="text-3xl">{dataForValidate?.bank}</p>
              <p className="text-3xl">{dataForValidate?.bookbank_id}</p>
              <p className="text-2xl">{dataForValidate?.fullname}</p>
            </div>
          </div>
        </div>
      ) : null}

      {previewImageCentral ? (
        <img
          onClick={() => setPreviewImageCentral(false)}
          src={imageURLPreview}
          className=" cursor-pointer absolute left-1/2 top-10 transform -translate-x-1/2  h-auto z-50"
        />
      ) : null}

      <div className="w-full h-[200px]  bg-black ">
        <div className="text-white h-full grid grid-cols-4">
          {statDisplay.map((data) => {
            return (
              <div className="w-full h-full flex flex-col justify-center items-center">
                <h3>{data.title}</h3>
                <span className="text-5xl">{data.count}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="relative h-[600px]  sm:rounded-lg overflow-y-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:text-gray-400">
            <tr>
              {/* Table headers */}
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                ตรวจสอบ
              </th>
              <th scope="col" className="px-6 py-3">
                Full Name
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Bank
              </th>
              <th scope="col" className="px-6 py-3">
                Bank ID
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Bankbook Image
              </th>
              <th scope="col" className="px-6 py-3">
                ID Card Image
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3 bg-gray-50 dark:bg-gray-800">
                Cut Fees & Tax
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawalData.map(
              (
                {
                  verify_data,
                  amount,
                  date,
                  cut_fees_and_tax,
                  withdraw_status,
                  withdraw_id,
                  uid,
                  userData,
                },
                index
              ) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 dark:border-gray-700"
                >
                  <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap bg-gray-50 dark:text-white dark:bg-gray-800">
                    {/* {verify_data.uid.length > 5
                      ? verify_data.uid.substring(0, 5) + "..."
                      : verify_data.uid} */}
                    <div
                      className="w-42 h-full cursor-pointer  text-white p-2.5 text-center bg-blue-500"
                      onClick={() => handleValidateData(verify_data)}
                    >
                      <span>ตรวจสอบข้อมูล</span>
                    </div>

                    <div
                      className="w-42 h-full cursor-pointer  text-white p-2.5 text-center bg-green-500"
                      onClick={() =>
                        createTransactionDocument(
                          verify_data,
                          amount,
                          date,
                          cut_fees_and_tax,
                          withdraw_status,
                          withdraw_id,
                          uid,
                          userData
                        )
                      }
                    >
                      <span>พิมพ์เอกสาร(ทดลอง)</span>
                    </div>
                  </td>
                  <td className="px-6 py-4">{verify_data.fullname}</td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {verify_data.bank}
                  </td>
                  <td className="px-6 py-4">{verify_data.bookbank_id}</td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {verify_data.bankbook_image.map((image, index) => (
                      <img
                        onClick={() => {
                          handleShowPreviewImage(image);
                        }}
                        key={index}
                        src={image}
                        alt={`Bankbook Image ${index}`}
                        className="h-16 cursor-pointer"
                      />
                    ))}
                  </td>
                  <td className="px-6 py-4">
                    {verify_data.idcard_image.map((image, index) => (
                      <img
                        onClick={() => {
                          handleShowPreviewImage(image);
                        }}
                        key={index}
                        src={image}
                        alt={`ID Card Image ${index}`}
                        className="h-16 cursor-pointer"
                      />
                    ))}
                  </td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {amount}
                  </td>
                  <td className="px-6 py-4">
                    {new Date(date).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                    {cut_fees_and_tax}
                  </td>
                  <td className="px-6 py-4">
                    <select
                      value={withdraw_status}
                      onChange={(e) =>
                        handleChangeStatus(
                          index,
                          e.target.value,
                          withdraw_id,
                          uid
                        )
                      }
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </select>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default WithdrawLogs;
