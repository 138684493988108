import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import {
  CardHeader,
  CardBody,
  Typography,
  Button,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { motion } from "framer-motion";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { MapIcon } from "@heroicons/react/24/solid";
import { PuffLoader } from "react-spinners";
import Swal from "sweetalert2";
import { Select, Option } from "@material-tailwind/react";

// Utility

function Lead(): JSX.Element {
  const token = localStorage.getItem("token");
  const [leadData, setLeadData] = useState<LeadAssets[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("all");
  const [filteredData, setFilteredData] = useState<LeadAssets[]>([]);
  const [searchLeadValue, setSearchLeadValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [LimitEstate, setLimitEstate] = useState("100");
  interface LeadAssets {
    images_url: string[];
    lat: string | null;
    lon: string | null;
    description: string;
    date: string;
    phone_number: string;
    status: string;
    lead_id: string;
    inviter: {
      uname: string;
      profile_image: string;
      role: string;
      phone_number: string;
      age: string;
      uid: string;
    };
    googlemap_url: string;
  }

  const TABS = [
    {
      label: "All",
      value: "all",
    },

    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Waiting",
      value: "waiting",
    },
    {
      label: "Success",
      value: "success",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];

  const statusButtons = [
    {
      label: "pending",
      action: "waiting",
    },
    {
      label: "waiting",
      action: "create_post",
    },
    {
      label: "success",
      action: "noting",
    },
    {
      label: "failed",
      action: "noting",
    },
  ];
  useEffect(() => {
    getReportLead();
  }, []);

  useEffect(() => {
    getReportLead();
  }, [LimitEstate, pageNumber]); // Add LimitEstate and pageNumber as dependencies

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScrollToTop = () => {
    console.log("Clicked!");
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const getReportLead = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_ADMIN_API}/reports/lead?sort=ASC&limit=${LimitEstate}&page=${pageNumber}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res: any) => {
        console.log(res.data);
        const data: LeadAssets[] = res.data.data;
        setLeadData(data);
        setFilteredData(data); // Set Initial After fetch show All Assets
        setLoading(false);
        console.log("check Lead Data Count", data.length);
      })
      .catch((err) => {
        window.location.replace("/login");
        console.log(err);
        const errMsg = err.message;
        localStorage.removeItem("token");
        localStorage.removeItem("permission");
        setLoading(false);
      });
  };

  function handleNavigateToMap(
    lat: number | null | string,
    long: number | null | string,
    mapsUrl: string
  ) {
    if (lat !== null && long !== null) {
      console.log("Latitude:", lat);
      console.log("Longitude:", long);

      // Construct Google Maps URL

      window.open(mapsUrl, "_blank");
    } else {
      console.log("Latitude or Longitude is null.");
      Swal.fire("ไม่มีตำแหน่งในฐานข้อมูล", "", "error");
    }
  }

  const handleSortedByStatus = (status: string) => {
    setSelectedTab(status);

    if (status === "all") {
      // If 'All' tab is selected, show all lead data without filtering
      setFilteredData(leadData);
    } else {
      // Filter lead data based on the selected status
      const filteredData = leadData.filter((lead) => lead.status === status);
      setFilteredData(filteredData);
    }
  };
  function handleChangeStatusLead(
    status: string,
    lead_id: string,
    statusLabel: string
  ) {
    if (status === "waiting" && statusLabel === "waiting") {
      window.location.replace("/create/select/post");
      sessionStorage.setItem("lead_id_posts", lead_id);
    } else {
      try {
        axios
          .post(`${process.env.REACT_APP_ADMIN_API}/reports/lead/update`, {
            report_state: statusLabel,
            lead_id: lead_id,
          })
          .then((res) => {
            const message = res.data.message;
            Swal.fire(`${message}`, "", "success");
            getReportLead();
          })
          .catch((err) => {
            console.log(err);
            Swal.fire(`${err.response.data.Error}`, "", "error");
          });
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleNote(id: string) {
    console.log(id);
    sessionStorage.setItem("idasset_note", id);
    window.open("/lead/note", "_blank");
  }

  function handleSearchLead(e: React.ChangeEvent<HTMLInputElement>): void {
    const searchValue_LeadID = e.target.value;
    setSearchLeadValue(searchValue_LeadID);
  }

  function handleDeleteLead(id: string) {
    Swal.fire({
      title: 'คุณแน่ใจหรือไม่?',
      text: `คุณต้องการลบ LEAD ${id} ใช่หรือไม่? การกระทำนี้ไม่สามารถย้อนกลับได้`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'ใช่, ลบเลย!',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log(id);
  
        axios
          .delete(`${process.env.REACT_APP_ADMIN_API}/reports/lead/delete/${id}`,{
            headers : {
              Authorization : `Bearer ${token}`
            }
          })
          .then((response) => {
            console.log("ลบ LEAD สำเร็จ:", response.data);
  
            // แสดงข้อความแจ้งเตือนเมื่อสำเร็จ
            Swal.fire({
              title: 'ลบสำเร็จ!',
              text: 'LEAD ถูกลบเรียบร้อยแล้ว',
              icon: 'success',
              confirmButtonText: 'ตกลง',
            });
          })
          .catch((error) => {
            console.error("เกิดข้อผิดพลาดในการลบ LEAD:", error);
  
            // แสดงข้อความแจ้งเตือนเมื่อเกิดข้อผิดพลาด
            Swal.fire({
              title: 'เกิดข้อผิดพลาด!',
              text: 'ไม่สามารถลบ LEAD ได้ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'ตกลง',
            });
          });
      }
    });
  }
  function enterSearchLead(): void {
    setLoading(true);
    setLeadData([]);
    setFilteredData([]);
    try {
      axios
        .get(
          `${process.env.REACT_APP_ADMIN_API}/reports/lead/search?sort=ASC&leadID=${searchLeadValue}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res: any) => {
          const data: LeadAssets[] = res.data;
          setLeadData(data);
          setFilteredData(data); // Set Initial After fetch show All Assets
          setLoading(false);
        });
    } catch (err: any) {
      console.log(err);
      const errMsg = err.message;
      setLoading(false);
    }
  }

  console.log("Filter Data", filteredData);
  return (
    <div className="w-full mx-auto ">
      <div className="relative w-full h-full flex   rounded  mx-auto border">
        <input
          onChange={(e) => handleSearchLead(e)}
          type="text"
          className=" absolute top-5 right-44  h-12 text-left rounded-full p-1 indent-2 w-[400px] outline-0 text-xl border broder-red-500 "
          placeholder="ค้นหา ID Lead "
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // Call your function here
              // For example:
              enterSearchLead();
            }
          }}
        />

        <div className="w-72 absolute left-1/2 top-[25px] z-50  transform -translate-x-1/2 ">
          <Select
            value={LimitEstate}
            label="ระบุจำนวนทรัพย์ที่ต้องการแสดง"
            className=""
            onChange={(e: any) => {
              setLimitEstate(e);
              getReportLead(); // Fetch new data when the limit changes
            }}
          >
            <Option defaultChecked value="100">
              100 ทรัพย์
            </Option>
            <Option value="300">300 ทรัพย์</Option>
            <Option value="900">900 ทรัพย์</Option>
            <Option value="2700">2700 ทรัพย์</Option>
            <Option value="8100">8100 ทรัพย์</Option>
          </Select>
        </div>

        <div className="absolute left-0 top-[63px] bg-white z-50 flex p-1 w-max flex-col items-center justify-between gap-4 md:flex-row md:bg-transparent md:top-3">
          <Tabs
            value={selectedTab}
            className="w-full md:w-max rounded-full overflow-hidden"
          >
            <TabsHeader className="flex rounded-full">
              {TABS.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => handleSortedByStatus(value)}
                  className="p-2.5 rounded-full" // Ensure each tab is fully rounded
                >
                  &nbsp;&nbsp;{label}&nbsp;&nbsp;
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>

        <span className="absolute top-8  right-16 text-xl">{`จำนวน ${filteredData.length}`}</span>

        {/* <LongDialog /> */}
        <div
          className="absolute bottom-10 right-12 text-5xl bg-gray-100 z-50 rounded-full cursor-pointer hover:bg-black hover:text-white"
          onClick={() => handleScrollToTop()}
        >
          <MdOutlineKeyboardArrowUp />
        </div>
        <div
          ref={containerRef}
          className="grid grid-cols-12 gap-x-2 flex-wrap h-[800px] overflow-y-auto w-full mt-24"
        >
          {filteredData.map((lead: LeadAssets, index: number) => {
            const lead_ID = lead?.lead_id;
            const imageUrl = lead?.images_url[0] ?? "";
            const phoneNumber = lead?.phone_number;
            const descr = lead?.description;
            const u_name = lead?.inviter?.uname;
            const date = lead?.date;
            const profile = lead?.inviter?.profile_image;
            const lat = lead?.lat;
            const lng = lead?.lon;
            const status = lead?.status;
            const mapUrl = lead?.googlemap_url;
            return (
              <motion.div
                className="relative  border rounded mt-6 w-90 col-span-12 cursor-pointer hover:bg-gray-100 hover:rounded-xl md:col-span-3"
                key={lead_ID}
                // whileHover={{ scale: 1.05 }} // Scale on hover
              >
                <CardHeader className="relative h-56 bg-black  mt-6">
                  <img
                    src={imageUrl}
                    alt="card-image"
                    className="w-full  h-full object-contain"
                    loading="lazy"
                  />

                  <div className="pt-0 w-80 text-center absolute left-1/2 bottom-2 tranform -translate-x-1/2">
                    <div className="text-white">{lead_ID}</div>
                  </div>

                  <div
                    className="pt-0 absolute right-8 bottom-12 tranform -translate-x-1/2 w-8  h-8 text-white z-50 hover:bg-white hover:text-black rounded-full  "
                    onClick={() => handleNavigateToMap(lat, lng, mapUrl)}
                  >
                    <MapIcon />
                  </div>

                  <div
                    className="pt-0 absolute left-6 top-[20px] bg-white/30   w-12  h-12 flex justify-center items-center text-white z-40 hover:bg-white hover:text-black rounded-full  "
                    onClick={() => handleNote(lead_ID)}
                  >
                    <img
                      className="w-8 h-8 "
                      src="https://cdn-icons-png.flaticon.com/512/1001/1001371.png"
                      alt="icon-note"
                    />
                  </div>

                  <div
                    className="pt-0 absolute left-6 top-[80px] bg-white/30   w-12  h-12 flex justify-center items-center text-white z-40 hover:bg-white hover:text-black rounded-full  "
                    onClick={() => handleDeleteLead(lead_ID)}
                  >
                    <img
                      className="w-8 h-8 "
                      src="   https://cdn-icons-png.flaticon.com/512/6861/6861362.png "
                      alt="icon-note"
                    />
                  </div>
                </CardHeader>
                <CardBody>
                  <Typography
                    variant="h5"
                    color="blue-gray"
                    className="mb-2 font-main"
                  >
                    {phoneNumber}
                  </Typography>
                  <Typography className="font-main">
                    {`${descr !== null ? descr.slice(0, 115) : descr}..`}
                  </Typography>

                  <Typography className="font-main flex 0 w-full flex-wrap">
                    {statusButtons.map((data, idx) => {
                      return (
                        <motion.div
                          key={idx + data.label}
                          className={`border  p-2.5 w-1/2 h-12 flex justify-center items-center rounded-full mb-1  ${
                            status === data.label
                              ? "bg-postperty text-white rounded"
                              : ""
                          }`}
                          onClick={() =>
                            handleChangeStatusLead(status, lead_ID, data.label)
                          }
                        >
                          {data.label}
                        </motion.div>
                      );
                    })}
                  </Typography>
                  <p className="w-full text-center mt-2.5 text-gray-500">
                    คลิกเลือกสถานะ
                  </p>
                </CardBody>
                <div className="pt-0 absolute right-6       top-8">
                  <Button
                    className={
                      status === "pending"
                        ? "bg-orange-500 rounded-full"
                        : status === "success"
                        ? "bg-green-500 rounded-full"
                        : status === "waiting"
                        ? "bg-gray-400 rounded-full"
                        : "bg-red-500 rounded-full"
                    }
                  >
                    {status}
                  </Button>
                </div>
              </motion.div>
            );
          })}
          {loading && (
            <p className=" w-full flex justify-center items-center col-span-12 font-main mt-2.5 h-12">
              {loading ? <PuffLoader color="#000" /> : null}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Lead;
