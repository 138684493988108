import React, { useState } from 'react';
import axios from 'axios';

interface SharerListModalProps {
  assetId: string;
}

interface SharerData {
  date: string;
  asset_id: string;
  buyer_uname: string;
  buyer_phonenumber: string;
  buyer_uid: string;
  sharer_uname: string;
  sharer_uid: string;
}

const SharerListModal: React.FC<SharerListModalProps> = ({ assetId }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sharerList, setSharerList] = useState<SharerData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);



  function fetchSharerList(){
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_ADMIN_API}/event/getLogUsersAssest`,{
        asset_id : assetId
    }).then((res)=>{
        setSharerList(res.data)
        setIsLoading(false);
    }).catch((err)=>{
        console.log(err)
        setIsLoading(false);

    })
  }
  const handleOpenModal = (): void => {
    setIsOpen(true);
    fetchSharerList();
  };

  const formatDate = (dateString: string): string => {
    return new Date(dateString).toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <>
      <button
        onClick={handleOpenModal}
        type="button"
        className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
      >
        โชว์คนแชร์ทั้งหมด
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen px-4">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            
            <div className="relative bg-white rounded-lg w-full max-w-2xl">
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-semibold">รายชื่อผู้แชร์ทั้งหมด</h3>
                  <button
                    onClick={() => setIsOpen(false)}
                    type="button"
                    className="text-gray-500 hover:text-gray-700"
                    aria-label="Close modal"
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {isLoading ? (
                  <div className="flex justify-center items-center py-8">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
                  </div>
                ) : (
                  <div className="overflow-y-auto max-h-96">
                    <table className="w-full">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-4 py-2 text-left text-sm font-medium text-gray-500">ชื่อผู้แชร์</th>
                          <th scope="col" className="px-4 py-2 text-left text-sm font-medium text-gray-500">วันที่</th>
                          <th scope="col" className="px-4 py-2 text-left text-sm font-medium text-gray-500">ชื่อผู้ซื้อ</th>
                          <th scope="col" className="px-4 py-2 text-left text-sm font-medium text-gray-500">เบอร์โทรผู้ซื้อ</th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {sharerList.map((sharer, index) => (
                          <tr key={`${sharer.sharer_uid}-${index}`} className="hover:bg-gray-50">
                            <td className="px-4 py-3">{sharer.sharer_uname}</td>
                            <td className="px-4 py-3">{formatDate(sharer.date)}</td>
                            <td className="px-4 py-3">{sharer.buyer_uname}</td>
                            <td className="px-4 py-3">{sharer.buyer_phonenumber}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    {sharerList.length === 0 && (
                      <div className="text-center py-4 text-gray-500">
                        ไม่พบข้อมูลการแชร์
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SharerListModal;