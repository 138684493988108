import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import SharerListModal from "./SharerListModal";


export default function ApprovedAsset(): JSX.Element {
  interface OfficialAssetStruct {
    id: string;
    // phone_number: string;
    AssetImagesURL: string[];
    time: string;
    description: string;
    lead_uid: string | null;
    leadUser : {
      profile_image : string,
      uid : string,
      uname : string,
    }
    // member: {
    //   profile_image: string;
    //   uname: string;
    //   phone_number: string;
    // };
  }

  interface ApprovedAsset {
    assset_id : string,
    buyer_uid : string,
    sharer_uid : string,
  }

  interface buyerData {
    uname : string,
    uid : string,
    profile_image : string,
    phone_number : string,
  }


  interface buyerDataDisplay {
    uname : string,
    uid : string,
    profile_image : string,
    phone_number : string,
  }


 


  interface sharerData {
    date : string,
    asset_id : string,
    buyer_uname : string,
    buyer_phonenumber : string,
    buyer_uid : string,
    sharer_uname : string,
    sharer_uid : string,
  }
  
  interface sharerDataDisplay {
    sharer_uname : string,
    sharer_uid : string,
    sharer_profile_image : string,
  }


  interface leadUserDataStruc {
    profile_image : string,
    uid : string,
    uname : string,
  }

  const initApprvoedAsset : ApprovedAsset = {
    assset_id : "",
    buyer_uid : "",
    sharer_uid : "",
  };

  const initBuyerDataDisplay : buyerData = {
    uname : "",
    uid : "",
    profile_image : "",
    phone_number : "",
  }

  const initSharerDataDisplay : sharerDataDisplay = {
    sharer_uname : "",
    sharer_uid : "",
    sharer_profile_image : "",
  }

  const initLeadUserData : leadUserDataStruc = {
    profile_image : "",
    uname : "",
    uid : "",
  }

  const [official_assets,setOfficial_Assets] = useState<OfficialAssetStruct[]>([]);
  const [approvedAssetData,setApprovedAssetData] = useState<ApprovedAsset>(initApprvoedAsset)
  const [buyerData,setBuyerData] = useState<buyerData[]>([]);
  const [buyerDataDisplay , setBuyerDataDisplay] = useState<buyerDataDisplay>(initBuyerDataDisplay)
  const [sharerData,setSharerData] = useState<sharerData[]>([])
  const [sharerDataDisplay,setSharerDataDisplay] = useState<sharerDataDisplay>(initSharerDataDisplay)
  const [leadUserData , setLeadUserData] = useState<leadUserDataStruc>(initLeadUserData)
  useEffect(() => {
    getOfficalAsset();
  }, []);
  useEffect(()=>{
    getinitBuyerFromPhoneNumber()
  },[])

  const token = localStorage.getItem(`token`);
  function getOfficalAsset(): void {
    axios
      .get(`${process.env.REACT_APP_ADMIN_API}/fetchData/official_asset`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("this is data lead" , res.data.data);
        const data = res.data?.data;
        
        setOfficial_Assets(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }


  function SelectOfficialAssetForApprove(selectedAssetId : string , leadUserData : any) : void {

    setLeadUserData(leadUserData)
    setApprovedAssetData({...approvedAssetData, assset_id : selectedAssetId , sharer_uid : "" , buyer_uid : ""});


    // Clear Old Data
    // setBuyerData([]);
    // setBuyerDataDisplay(initBuyerDataDisplay)
    // setSharerData([]);
    // setSharerDataDisplay(initSharerDataDisplay);
    

      }


      function getinitBuyerFromPhoneNumber(): void {
        axios.get(`${process.env.REACT_APP_ADMIN_API}/fetchData/search/buyer?phoneNum=`,{
          headers : {
            Authorization : `Bearer ${token}`
          }
        }).then((res)=>{
          const data = res.data;
          setBuyerData(data)
        }).catch((err)=>{
          console.log(err)
        })
      }

   

      function searchBuyerUID(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ): void {
    
        const value = e.target.value
        console.log(value)
        axios.get(`${process.env.REACT_APP_ADMIN_API}/fetchData/search/buyer?phoneNum=${value}`,{
          headers : {
            Authorization : `Bearer ${token}`
          }
        }).then((res)=>{
          const data = res.data;
          setBuyerData(data)
        }).catch((err)=>{
          console.log(err)
        })
       
      }
      function handleBuyer(buyerData : any) : void{
        console.log("Buyer Data" , buyerData)
        setBuyerDataDisplay(buyerData)
        setApprovedAssetData({...approvedAssetData , buyer_uid : buyerData?.uid })


        // Call Function Search Share
        searchSharerUID(buyerData?.phone_number)
      }

      function handleSharer(sharerData : any) : void{
        console.log(sharerData)
        setSharerDataDisplay(sharerData)
        setApprovedAssetData({...approvedAssetData , sharer_uid : sharerData?.sharer_uid })
      }


      


      // function searchSharerUID(
      //   e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      // ):void {
      //   const value = e.target.value;
      //   console.log("sharer search" , value)
      //   if(approvedAssetData.assset_id !== ""){
      //     axios.post(`${process.env.REACT_APP_ADMIN_API}/event/getSharelog`,{
      //       asset_id : approvedAssetData?.assset_id,
      //       buyer_phonenum : value
      //     }).then((res)=>{
      //       const data = res.data;
      //       setSharerData(data)
      //     })

      //   }else{
      //     Swal.fire(``,`กรุณาเลือกอสังหาฯก่อนค้นหาผู้แชร์`,"error")
      //   }
     
        
      // }

      function searchSharerUID( phone_number  : string ):void {
        console.log("phone_number search" , phone_number)
      
        if(approvedAssetData.assset_id !== ""){
          axios.post(`${process.env.REACT_APP_ADMIN_API}/event/getSharelog`,{
            asset_id : approvedAssetData?.assset_id,
            buyer_phonenum : phone_number
          }).then((res)=>{
            const data = res.data;
            setSharerData(data)
          })

        }else{
          Swal.fire(``,`กรุณาเลือกอสังหาฯก่อนค้นหาผู้แชร์`,"error")
        }
     
        
      }

      


      function searchOfficalAsset(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,

      ): void {
        const aid = e.target.value
        axios
          .get(`${process.env.REACT_APP_ADMIN_API}/fetchData/official_asset?aid=${aid}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data.data);
            const data = res.data?.data;
            setOfficial_Assets(data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    
    

      function approvedAssetWithData() : void{
        if(approvedAssetData.assset_id !== "" && approvedAssetData.buyer_uid !== ""){
          axios.post(`${process.env.REACT_APP_ADMIN_API}/commission/admin/approve-asset`,{
            asset_id : approvedAssetData.assset_id,
            buyer_uid : approvedAssetData.buyer_uid,
            sharer_uid : approvedAssetData.sharer_uid,
          }).then((res)=>{
            Swal.fire(`อนุมัติสำเร็จ`,`${approvedAssetData.assset_id}`,"success")
            console.log(res.data)
          }).catch((err)=>{
            console.log(err)
          })
        }else{
          Swal.fire(``,`ข้อมูลไม่ครบกรุณาเลือกอสังหาฯ ผู้ซื้อและผู้ขาย`,"error")
        }
      
      }
  return (
    <main className=" w-full  mt-24 md:mt-0">

      <div className="w-full text-right h-12   flex justify-end items-center">
        <span onClick={()=>approvedAssetWithData()} className="w-48 bg-postperty h-12 text-white rounded-full flex justify-center items-center p-2.5 cursor-pointer">อนุมัติอสังหา</span>
      </div>

      <div className="w-full grid grid-cols-1 h-[29%]   ">
        <div className="w-full h-[250px]  flex flex-col">
          <div className="relative grid h-[400px] w-full max-w-[100%] flex-col items-end justify-center overflow-hidden  bg-white bg-clip-border text-center text-gray-700">
            <div className=" absolute inset-0 m-0 h-full w-full overflow-hidden rounded-none bg-transparent bg-[url('https://images.unsplash.com/photo-1550850839-8dc894ed385a?q=80&w=1750&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')] bg-cover bg-clip-border bg-center text-gray-700 shadow-none">
              <div className="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-t from-black/80 via-black/50"></div>
            </div>
            <div className="relative p-6 px-6  md:px-12">
              <h2 className="mb-6 block  text-4xl font-medium leading-[1.5] tracking-normal text-white antialiased">
                {`ยืนยันการอนุมัติอสังหาฯ  ${approvedAssetData.assset_id}`}
              </h2>
              <div className="flex justify-around items-center  w-full h-full ">
              <div className="flex flex-col justify-center items-center  ">
                  <h5 className="block mb-4  text-xl antialiased font-semibold leading-snug tracking-normal text-gray-400">
                   {`คน Lead ${leadUserData?.uname}`}
                  </h5>
                  <img
                    alt="lead_profile"
                    src={`${leadUserData?.profile_image}`}
                    className="relative inline-block h-[74px] w-[74px] !rounded-full border-2 border-white object-cover object-center"
                  />
                </div>
                <div className="flex flex-col justify-center items-center  ">
                  <h5 className="block mb-4  text-xl antialiased font-semibold leading-snug tracking-normal text-gray-400">
                   {`คนซื้อ ${buyerDataDisplay?.uname}`}
                  </h5>
                  <img
                    alt="buyer"
                    src={`${buyerDataDisplay?.profile_image}`}
                    className="relative inline-block h-[74px] w-[74px] !rounded-full border-2 border-white object-cover object-center"
                  />
                </div>
                <div className="flex flex-col justify-center items-center  ">
                  <h5 className="block mb-4  text-xl antialiased font-semibold leading-snug tracking-normal text-gray-400">
                  {`คนแชร์ ${sharerDataDisplay?.sharer_uname}`}
                  </h5>
                  <img
                    alt="sharer"
                    src={`${sharerDataDisplay?.sharer_profile_image}`}
                    className="relative inline-block h-[74px] w-[74px] !rounded-full border-2 border-white object-cover object-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full grid grid-cols-1 h-[60%]   lg:grid-cols-3">
        
        <div className="relative w-full h-full max-h-[600px] grid grid-cols-2 overflow-auto  ">
        <input onChange={(e)=>searchOfficalAsset(e)} type="text" placeholder="ค้นหา ID อสังหาฯ" className="absolute top-2.5 left-1/2 transform -translate-x-1/2 z-50 h-12 outline-none  bg-gray-100 rounded-xl w-full text-center"/>

            {official_assets.map((asset)=>{
              return (
                <div className="relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
            <div className="relative mx-4 mt-4 overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40">
              <img
                src={asset.AssetImagesURL[0]}
                alt={asset.description}
                className="aspect-video	phone_number"
              />
              <div className="absolute inset-0 w-full h-full to-bg-black-10 bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
              <button
                className="!absolute  top-4 right-4 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-full text-center align-middle  text-xs font-medium uppercase text-red-500 transition-all hover:bg-red-500/10 active:bg-red-500/30 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
              >
                <span className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-6 h-6"
                  >
                    <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z"></path>
                  </svg>
                </span>
              </button>

              <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full text-center">{asset.id}</span>
            </div>
            <div className="p-6">
              <div className="flex items-center justify-between mb-3 ">
                <h5 className="block  text-xl antialiased font-medium leading-snug tracking-normal text-blue-gray-900">
                  {/* {asset.phone_number} */}
                </h5>
                <p className="flex items-center gap-1.5  text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="-mt-0.5 h-5 w-5 text-yellow-700"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  5.0
                </p>
              </div>
              <p className="block  text-base antialiased font-light leading-relaxed text-gray-700">
                {asset.description.slice(0,50)}
              </p>
             
            </div>
            <div className="p-6 pt-3">
              <button
                className="block w-full select-none rounded-lg bg-gray-900 py-3.5 px-7 text-center align-middle  text-sm font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={()=>SelectOfficialAssetForApprove(asset.id , asset?.leadUser)}
              >
                เลือก
              </button>
            </div>
          </div>
              )
            })}
        </div>
        <div className="border w-full h-[600px] overflow-auto p-2.5">
          <input onChange={(e)=>searchBuyerUID(e)} type="text" placeholder="ค้นหาผู้ซื้อด้วยเบอร์โทร" className="h-12 outline-none bg-gray-100 rounded-xl w-full text-center"/>
          <div className="w-18 h-12 grid grid-cols-2 mt-2.5">{buyerData?.map((data)=>{
            return(
              <div className="cursor-pointer border h-12 p-2.5 rounded" onClick={()=>handleBuyer(data)} >{data.uname}</div>
            )
          })}</div>
        </div>
        <div className="border w-full h-[600px] overflow-auto p-2.5">

          <input value={buyerDataDisplay?.phone_number} type="text" placeholder="ค้นหาคนแชร์ด้วยเบอร์โทร"  className="h-12 outline-none bg-gray-100 rounded-xl w-full text-center"/>
          <div className="w-18 h-12 grid grid-cols-2 mt-2.5">{sharerData?.map((data)=>{
            return(
              <div className="cursor-pointer border h-18 p-2.5 rounded" onClick={()=>handleSharer(data)} >{`${ data?.sharer_uname }\n เวลา :${data?.date}`}</div>
            )
          })}
<span className="flex justify-center items-center rounded-md w-full col-span-12 text-center border p-4">
  {sharerData.length > 0 ? null : (
    <div className="flex flex-col gap-2">
      <p>ผู้ซื้อไม่ผ่านลิงค์แชร์ของใคร สามารถอนุมัตอสังหาฯได้</p>
      <SharerListModal assetId={approvedAssetData.assset_id} />
    </div>
  )}
</span>          </div>
        </div>
      </div>
    </main>
  );
}
